import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
  if (!re.test(String(email).toLowerCase())) {
    return <FormattedMessage id="contactForm.email.invalidEmail" />
  }
}

const required = (value, key) => {
  if (!value || value === '' || value.trim() === '') {
    let reqId = `contact.${key}.errorMessage`
    return <FormattedMessage id={reqId} />
  }
}

const validationConfig = [{ key: 'name' }, { key: 'message' }, { key: 'email' }]

export function validateForm(formValues, locale) {
  let err = {}
  validationConfig.forEach(item => {
    const requiredValidation = required(formValues[item.key], item.key, locale)
    err[item.key] = requiredValidation ? [requiredValidation] : null
  })
  const emailValidation = validateEmail(formValues.email, locale)
  if (emailValidation) {
    if (err.email) {
      err.email.push(emailValidation)
    } else {
      err.email = [emailValidation]
    }
  }
  return err
}
