import React, { useState } from 'react'
import { injectIntl, IntlContextConsumer} from 'gatsby-plugin-intl'
import { validateForm } from '../../utils/utils'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const displayErrorMessage = (errors, label, meta, submitted) =>
  errors[label] &&
  (meta[label] || submitted) && (
    <span className="error-message">
      {errors[label].map((err, index) => (
        <span key={index} style={{ display: 'block' }}>{err}</span>
      ))}
    </span>
  )

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const initialForm = {
  name: '',
  email: '',
  message: ''
}

const FormSection = ({ intl }) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [form, setForm] = useState(initialForm)
  const [recaptcha, setRecaptcha] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [meta, setMeta] = useState({})
  const errors = validateForm(form)
  const handleChangeField = e => {
    setMeta({
      ...meta,
      [e.target.name]: 'touched'
    })
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  const handleChangeRecaptcha = value => {
    setRecaptcha(value)
  }
  const handleSubmit = e => {
    setSubmitted(true)
    if (
      Object.keys(errors).filter(key => errors[key]).length === 0 &&
      recaptcha
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          'g-recaptcha-response': recaptcha,
          ...form
        })
      })
        .then(() => setSuccess(true))
        .catch(() => setError(true))
    }

    e.preventDefault()
  }
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-5">
            <h6 className="uppercase">
              {intl.formatMessage({ id: 'contactPage.Get_In_Touch' })}
            </h6>
            <h3 className="uppercase">Appify-IT</h3>
            <p>{intl.formatMessage({ id: 'contactPage.headquarters' })}</p>
            <hr />
            <p>
              {intl.formatMessage({ id: 'contactPage.address_1' })}
              <br /> {intl.formatMessage({ id: 'contactPage.address_2' })}
              <br /> {intl.formatMessage({ id: 'contactPage.address_3' })}
              <br /> 2045
            </p>
            <hr />
            <p>
              <strong>E:</strong> contact@appify-it.com
              <br />
              <strong>P:</strong> +216 99 89 10 03
              <br />
            </p>
          </div>
          <div className="col-sm-6 col-md-5 col-md-offset-1">
            <form
              data-netlify-recaptcha="true"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              {error && (
                <div>
                  <h3>{intl.formatMessage({
                    id: 'contactForm.send.error'
                  })}}</h3>
                </div>
              )}
              {success ? (
                <div>
                  <h2>
                    {intl.formatMessage({
                      id: 'contactForm.send.success'
                    })}
                  </h2>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    className="validate-required"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: 'contactForm.name.placeholder'
                    })}
                    onChange={handleChangeField}
                  />
                  {displayErrorMessage(errors, 'name', meta, submitted)}
                  <input
                    type="text"
                    className="validate-required validate-email"
                    name="email"
                    onChange={handleChangeField}
                    placeholder={intl.formatMessage({
                      id: 'contactForm.email.placeholder'
                    })}
                  />
                  {displayErrorMessage(errors, 'email', meta, submitted)}
                  <textarea
                    className="validate-required"
                    name="message"
                    rows="4"
                    onChange={handleChangeField}
                    placeholder={intl.formatMessage({
                      id: 'contactForm.message.placeholder'
                    })}
                  />
                  {displayErrorMessage(errors, 'message', meta, submitted)}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IntlContextConsumer>
                      {({ language: currentLocale }) =>
                        (
                          <Recaptcha
                            hl={currentLocale}
                            sitekey={RECAPTCHA_KEY}
                            onChange={handleChangeRecaptcha}
                          />
                        )
                      }
                    </IntlContextConsumer>

                  </div>
                  <button
                    style={{ marginTop: '24px' }}
                    disabled={recaptcha === null}
                    onClick={handleSubmit}
                    type="submit"
                  >
                    {intl.formatMessage({ id: 'contactPage.submit_btn' })}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(FormSection)
