import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
const MapSection = ({ intl }) => {
  return (
    <section className="p0">
      <div className="map-holder pt160 pb160">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.413714110552!2d10.266354315841733!3d36.856514979937224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzbCsDUxJzIzLjUiTiAxMMKwMTYnMDYuOCJF!5e0!3m2!1sfr!2sbe!4v1564088401918!5m2!1sfr!2sbe" />
      </div>
    </section>
  )
}

export default injectIntl(MapSection)
