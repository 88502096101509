import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import MapSection from '../components/ContactPage/MapSection'
import FormSection from '../components/ContactPage/FormSection'
import SEO from '../components/SEO'
import { injectIntl } from 'gatsby-plugin-intl'
const contact = ({ intl }) => {
  return (
    <Layout>
      <SEO title="contact us" />
      <PageTitle
        title={intl.formatMessage({ id: 'navBar.contact' })}
        subtitle={intl.formatMessage({ id: 'navBar.contactSubtitle' })}
      />
      <MapSection />
      <FormSection />
    </Layout>
  )
}

export default injectIntl(contact)
